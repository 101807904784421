import React, { useEffect } from "react";


const RedirectToSchool = () => {

useEffect(() => {
    window.location.href="https://e-campus-registration.bp.edu/special/breathing-science-for-birth-professionals"
})

return (

<></>
)
}

export default RedirectToSchool;
